<messages>["../Template"]</messages>

<template>
  <div>
    <span
      v-if="isEmpty"
      v-html="$t ('list.cond.none')"/>
    <template v-else>
      <div
        v-if="countryPattern"
        v-html="$t ('list.cond.country', {country: countryPattern})"/>
      <div
        v-if="languagePattern"
        v-html="$t ('list.cond.lang', {lang: languagePattern})"/>
      <div
        v-if="maintainerPattern"
        v-html="$t ('list.cond.maintainer', {maintainer: maintainerPattern})"/>
      <i18n
        v-if="registryTypeNames && registryTypeNames.length"
        tag="div"
        path="list.cond.registries.message">
        <template #title>
          <span v-html="$t ('list.cond.registries.title')"/>
        </template>
        <template #registries>
          <registry-type-name
            v-for="registryType in registryTypeNames"
            :key="registryType"
            class="registryType"
            :value="registryType" cut-tlds/>
        </template>
      </i18n>
    </template>
  </div>
</template>

<script>
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  export default {
    name: 'TemplateConditions',

    components: {
      RegistryTypeName
    },

    props: {
      countryPattern: {type: String, default: ''},
      languagePattern: {type: String, default: ''},
      maintainerPattern: {type: String, default: ''},
      registryTypeNames: {type: Array, default: () => []}
    },

    data () {
      return {
        disabledTypes: []
      }
    },

    computed: {
      isEmpty () {
        return !(this.countryPattern || this.languagePattern ||
          this.maintainerPattern || this.registryTypeNames?.length)
      }
    },

    watch: {},

    created () {
    },

    methods: {
      getConditionText (item) {
        const textLines = []
        const country = item.countryPattern
        const lang = item.languagePattern
        const maintainer = item.maintainerPattern
        const registries = item.registryTypeNames

        if (!country && !lang && !maintainer && !registries) {
          textLines.push (this.$t ('list.cond.none'))
        } else {
          if (maintainer) {
            textLines.push (this.$t ('list.cond.maintainer', {maintainer}))
          }
          if (lang) {
            textLines.push (this.$t ('list.cond.lang', {lang}))
          }
          if (country) {
            textLines.push (this.$t ('list.cond.country', {country}))
          }
          if (registries?.length) {
            textLines.push (
              this.$t (
                'list.cond.registries', {registries: registries.join (',')}))
          }
        }

        return textLines.join ('<br/>')
      }
    }
  }
</script>

<style scoped>
.registryType:after {
  content: ",";
  margin-right: .3em;
}

.registryType:last-child:after {
  content: "";
  margin-right: 0;
}
</style>
