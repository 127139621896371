<messages>["./Template"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card class="search-result mb-6">
        <!-- toolbar for selected item operations -->
        <v-toolbar
          v-if="!!selectedItems.length"
          class="selectionSupport ma-4"
          floating
          dense>
          <v-toolbar-title v-text="$tc ('list.selected', selectedItems.length, {count: selectedItems.length})"/>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                color="error"
                v-on="on"
                @click="onDeleteTemplates (selectedItems)">
                <v-icon>delete_forever</v-icon>
              </v-btn>
            </template>
            <span v-text="$t('general.button.delete')">
              Delete
            </span>
          </v-tooltip>
        </v-toolbar>

        <v-card-title primary-title>
          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <div>
                <div
                  v-t="'list.title'"
                  class="text-h5"/>
                <div
                  v-t="'list.subTitle'"
                  class="cgwng-subheading"/>
              </div>
            </v-col>
            <!-- filter -->
            <v-col v-if="frontendListOperations" cols="12" sm="6">
              <v-text-field
                v-model.trim="search"
                append-icon="search"
                :label="$t ('general.label.search')"
                single-line
                hide-details
                clearable
                @keyup.esc="search = ''"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="templates"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="footerProps"
            :server-items-length="totalCount"
            :loading="loading"
            :no-data-text="noDataText (loading)"
            :no-results-text="noResultsText (loading)"
            class="elevation-1">
            <template #headerCell="props">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span
                    class="primary--text"
                    v-on="on">
                    {{ props.header.text }}
                  </span>
                </template>
                <span>{{ props.header.text }}</span>
              </v-tooltip>
            </template>
            <template #item="props">
              <tr>
                <!-- td>
                  <v-checkbox
                    v-model="props.selected"
                    color="primary"
                    hide-details/>
                </td -->
                <td>
                  <router-link
                    :to="{name: 'template.edit', params: {id: props.item.id}}"
                    v-text="props.item.name"/>
                </td>
                <td>{{ props.item.usage }}</td>
                <td>{{ props.item.objectType }}</td>
                <td>{{ props.item.priority }}</td>
                <td class="conditionColumn">
                  <template-conditions v-bind="props.item"/>
                </td>
                <td v-html="getOperationText (props.item)"/>
                <td v-if="isClientColumnVisible">
                  <client-link :id="props.item.clientId"/>
                </td>
                <td>
                  <action-buttons
                    :value="isActionButtonsActive (props.item.id)"
                    :buttons="getActionButtons (props.item)"
                    @input="state => setActionButtonsActive (props.item.id) (state)"
                    @clicked="processActionButton"/>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.refresh'"
            @click="listTemplates (true)"/>
          <v-btn
            v-if="hasAnyOfPermissions (['ManageAllEntities'])"
            v-t="'general.button.create'"
            color="primary"
            :to="{name: 'template.create'}"/>
        </v-card-actions>

        <!-- delete confirmation dialog -->
        <base-dialog
          v-if="showDeleteConfirmationDialog"
          v-model="showDeleteConfirmationDialog"
          mw0
          close-on-esc>
          <v-card>
            <v-card-title>
              <div
                class="text-h5"
                v-text="$t ('delete.title')"/>
            </v-card-title>
            <v-card-text
              v-text="$tc (
                'delete.message',
                itemsToDelete.length, {
                  id: itemsToDelete[0].id,
                  name: itemsToDelete[0].name,
                  count: itemsToDelete.length
                })"/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.cancel'"
                text
                @click.native="showDeleteConfirmationDialog = false"/>
              <v-btn
                v-t="'general.button.delete'"
                color="error"
                @click.native="deleteTemplates ()"/>
            </v-card-actions>
          </v-card>
        </base-dialog>
      </v-card>

      <template-test/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import ActionButtons from '@/app/core/components/ActionButtons'
  import BaseDialog from '@/app/core/components/BaseDialog'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  import ClientLink from '@/app/core/components/ClientLink'

  import TemplateTest from './TemplateTest'
  import TemplateConditions from './components/TemplateConditions'

  const SEARCHABLE_TEMPLATE_PROPS = [
    'usage',
    'clientId',
    'objectType',
    'priority',
    'templateType',
    'name'
  ]

  export default {
    name: 'TemplateList',

    components: {
      ActionButtons,
      BaseDialog,
      BaseLayout,
      ClientLink,
      TemplateTest,
      TemplateConditions
    },

    mixins: [paginationMixins, actionButtonsHelper],

    data () {
      return {
        // flag, which allows list filter (search) and client-side
        // sorting/paging
        frontendListOperations: true,
        search: '',
        cachedTemplates: [],
        templates: [],
        totalCount: 0,
        speedDial: {},
        selectedItems: [],
        itemsToDelete: [],
        showDeleteConfirmationDialog: false,
        loading: true
      }
    },

    computed: {
      // mix the state into computed with object spread operator
      ...mapGetters ('auth', [
        'permissions',
        'hasAnyOfPermissions',
        'isImpersonated',
        'mayManageForeignObjects'
      ]),

      isClientColumnVisible () {
        return this.mayManageForeignObjects
      },

      headers () {
        return [
          {
            text: this.$t ('label.name'),
            value: 'name'
          },
          {
            text: this.$t ('label.usage'),
            value: 'usage'
          },
          {
            text: this.$t ('label.type'),
            value: 'type'
          },
          {
            text: this.$t ('label.priority'),
            value: 'priority'
          },
          {
            class: 'conditionColumn',
            text: this.$t ('label.condition'),
            value: 'condition'
          },
          {
            text: this.$t ('label.operation'),
            value: 'operation'
          },
          ...(this.isClientColumnVisible)
            ? [{
              text: this.$t ('label.clientId'),
              value: 'clientId'
            }]
            : [],
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      }
    },

    watch: {
      search () {
        this.list ()
      }
    },

    created () {
      // this is a workaround to enforce data retrieve from BE
      // even if the pagination and other request parameters are not changed
      // (otherwise data are not fetched on page reload and browser "back" in history)
      // TODO, FIXME: need better solution to prevent request duplication
      this.onPaginationStateChanged (this.internalPaginationState)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        return [
          {
            action: 'edit',
            itemId: item.id,
            icon: 'edit',
            tooltip: this.$t ('general.button.edit')
          },
          {
            action: 'delete',
            actionArg: [item],
            icon: 'delete_forever',
            tooltip: this.$t ('general.button.delete'),
            color: 'error'
          }
        ]
      },

      list () {
        this.listTemplates ()
      },

      async requestTemplateList (params) {
        const templateListData = {
          templates: [],
          totalCount: 0
        }

        await this.fetchData ({
          op: 'template/list',
          ...params,
          cb: data => {
            templateListData.templates = data.list
            templateListData.totalCount = data.totalCount
          }
        })

        return [templateListData.templates, templateListData.totalCount]
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters (client-side filter, sorting, slicing)
       */
      performListOperation () {
        // filter
        if (this.search) {
          const searchNormalized = this.search.trim ().toLocaleLowerCase ()
          this.templates = this.cachedTemplates.filter (o => {
            let match
            for (const prop of SEARCHABLE_TEMPLATE_PROPS) {
              if (o.hasOwnProperty (prop)) {
                switch (typeof o[prop]) {
                  case 'string':
                    match =
                      o[prop].toLocaleLowerCase ().includes (searchNormalized)
                    break

                  case 'number':
                    match = o[prop] === Number.parseInt (searchNormalized)
                    break
                }

                if (match) {
                  break
                }
              }
            }
            return match
          })
        } else {
          this.templates = this.cachedTemplates.slice ()
        }
        this.totalCount = this.templates.length
        // sort
        if (this.pagination.sortBy) {
          this.templates.sort ((a, b) => {
            let prop = this.pagination.sortBy
            if (prop === 'type') prop = 'objectType'
            let valA = a[prop]
            let valB = b[prop]
            if (typeof valA === 'string' && typeof valB === 'string') {
              valA = valA.toLocaleLowerCase ()
              valB = valB.toLocaleLowerCase ()
            }
            const cmpRes = valA > valB ? 1 : valA === valB ? 0 : -1
            return this.pagination.descending ? -cmpRes : cmpRes
          })
        } else {
          // templates are sorted by Usage/Type/Priority ascending by default
          this.templates.sort ((a, b) => {
            return a.usage.localeCompare (b.usage) ||
              a.objectType.localeCompare (b.objectType) ||
              (a.priority - b.priority) ||
              a.name.localeCompare (b.name)
          })
        }
        // slice
        if (this.pagination.page > 0 && this.pagination.rowsPerPage > 0) {
          const start = (this.pagination.page - 1) * this.pagination.rowsPerPage
          const end = start + this.pagination.rowsPerPage
          this.templates = this.templates.slice (start, end)
        }
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters. If {@code this.frontendListOperations} is set,
       * then operation is performed on the client side.
       *
       * @param refresh {Boolean}       if {@code true}, then force to get the
       *                                list from BE
       */
      async listTemplates (refresh) {
        this.loading = true

        if (this.frontendListOperations) {
          // if not cached => get from server
          if (!(this.cachedTemplates.length > 0) || refresh) {
            [this.cachedTemplates] = await this.requestTemplateList ({})
            this.totalCount = this.cachedTemplates.length
          }
          // filter, sorting, slicing cached items
          this.performListOperation ()
        } else {
          [this.templates, this.totalCount] =
            await this.requestTemplateList (this.getPaginationForRequest ())
        }

        this.loading = false
      },

      getOperationText (item) {
        let text = ''

        const templateType = item.templateType

        if (templateType) {
          text = this.$t (`operation.${templateType}`)
        }

        return text
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'edit':
            this.$router.push ({
              name: 'template.edit',
              params: {id: button.itemId}
            })
            break
          case 'delete':
            this.onDeleteTemplates (button.actionArg)
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * show delete confirmation dialog
       *
       * @param itemsToDelete   items to be deleted
       */
      onDeleteTemplates (itemsToDelete) {
        this.itemsToDelete = itemsToDelete
        this.showDeleteConfirmationDialog = true
      },

      /**
       * delete templates, specified by {@code this.itemsToDelete}
       * (see onDeleteTemplates)
       *
       * @param successCallback     function to be called in success case
       */
      deleteTemplates (successCallback = this.afterDelete) {
        // hide confirmation dialog
        this.showDeleteConfirmationDialog = false
        const ids = this.itemsToDelete.map (e => e.id)

        this.fetchData ({
          op: 'template/delete',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (this.$tc ('deleted', ids.length, {
              id: this.itemsToDelete[0].id,
              name: this.itemsToDelete[0].name,
              count: ids.length
            }))

            successCallback (ids)
          }
        })
      },

      /**
       * state corrections after delete operation
       *
       * @param deletedIds    IDs of items, which was deleted
       */
      afterDelete (deletedIds) {
        // filter out deleted from selected items
        this.selectedItems =
          this.selectedItems.filter (it => !deletedIds.includes (it.id))
        // reset items to delete
        this.itemsToDelete = []
        // reset cache
        this.cachedTemplates = []
        // get the list from server
        this.listTemplates ()
      }
    }
  }
</script>

<style scoped>
.conditionColumn {
  max-width: 25vw;
  overflow: auto;
}
</style>
