<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-container
    fluid
    class="pa-0 pb-12">
    <v-row>
      <v-col
        class="d-flex"
        cols="12">
        <template-list v-bind="paginationStateFromRoute"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import templateList from './TemplateList'

  import paginationMixins from '@/app/core/mixins/PaginationContainer'

  export default {
    name: 'Template',

    components: {
      templateList
    },

    mixins: [paginationMixins]
  }
</script>
